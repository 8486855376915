<template>
  <div>
    <AppLogo class="mt-6 mb-8 cursor-pointer" @click="pushToStart" />

    <div v-for="(item, index) in items" :key="index">
      <NavigationItem :item="item" />
    </div>

    <UserNavigation />
    <TeacherNavigation />
    <ManagementNavigation />
  </div>
</template>

<script>
import NavigationItem from "./partials/NavigationItem";
import useMenuItems from "@use/useMenuItems";
import AppLogo from "./partials/AppLogo";
import menuName from "@data/menuNames";
import menuType from "@data/menuTypes";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import UserNavigation from "./partials/types/UserNavigation";
import TeacherNavigation from "./partials/types/TeacherNavigation";
import ManagementNavigation from "./partials/types/ManagementNavigation";
import { ref, computed, watch } from "vue";
import redirectRouteMenu from "@/modules/haw-components-hac/helpers/redirectRouteMenu";

export default {
  name: "Navigation",

  menuTypes: {
    type: Array,
    required: false,
    default: null,
  },

  components: {
    ManagementNavigation,
    TeacherNavigation,
    UserNavigation,
    NavigationItem,
    AppLogo,
  },

  setup() {
    const router = useRouter();
    const store = useStore();

    const routeName = ref(null);
    const expandedNavigation = ref(null);

    const { items } = useMenuItems({
      menuName: menuName.get().mainNavigation,
      menuTypes: [menuType.get().common],
    });

    const expand = async () => {
      await store.dispatch("hac/expandableNavigation/expand", expandedNavigation.value);
    };

    const pushToStart = async () => {
      try {
        await store.dispatch("hac/expandableNavigation/closeAll");
        await expand();
        await router.push({
          name: routeName.value,
        });
      } catch {
        return;
      }
    };

    const user = computed(() => {
      return store.getters["usm/authUser/user"];
    });

    const permissions = computed(() => {
      return store.getters["usm/authUser/permissions"];
    });

    const setValues = () => {
      const data = redirectRouteMenu.getRedirectData();

      expandedNavigation.value = data.expandedNavigation;
      routeName.value = data.routeName;

      expand();
    };

    watch(user, setValues);
    watch(permissions, setValues);

    setValues();

    return { items, pushToStart };
  },
};
</script>
