<template>
  <div class="flex haw-home" v-if="showPage">
    <SidebarLeft>
      <HAWNavigation />
    </SidebarLeft>

    <MainContent>
      <slot />
      <router-view v-if="!$slots.default" />
    </MainContent>
  </div>
</template>

<script>
import useApi from "@use/useApi";
import { useStore } from "vuex";
import SidebarLeft from "./partials/SidebarLeft.vue";
import MainContent from "./partials/MainContent.vue";
import HAWNavigation from "../Navigation/HAWNavigation";
import { ref } from "@vue/reactivity";
import { computed } from "vue";
import { useRouter } from "vue-router";

export default {
  name: "HAWHome",

  props: {
    public: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  components: {
    SidebarLeft,
    MainContent,
    HAWNavigation,
  },

  setup(props) {
    const store = useStore();
    const router = useRouter();

    const isAuthenticated = store.getters["usm/auth/isAuthenticated"];
    const user = store.getters["usm/authUser/user"];
    const authUserReady = ref(!!user);

    if (!authUserReady.value && isAuthenticated) {
      const { docs } = useApi({
        module: "usm",
        method: "showAuthUser",
        onMount: !props.public,
        onSuccess: async () => {
          await store.dispatch("usm/authUser/saveData", docs.value);
          authUserReady.value = true;
        },
        onError: async () => {
          try {
            await router.push({
              name: "Login",
            });
          } catch {
            return;
          }
        },
      });
    }

    const showPage = computed(() => {
      return !isAuthenticated || authUserReady.value;
    });

    return { showPage };
  },
};
</script>

<style lang="scss" scoped>
.haw-home {
  background-color: #ffffff;
}
</style>
