<template>
  <div v-if="items.length > 0">
    <div @click="toggle" class="cursor-pointer">
      <BaseDivider />
      <div
        class="flex justify-content-between align-items-center mb-3 py-1 px-3"
        :class="{ 'mb-3': isOpen }"
      >
        <div class="text-400 select-none">{{ $t(label) }}</div>
        <div>
          <BaseIcon :icon="icon" size="0.9" />
        </div>
      </div>
    </div>

    <div v-if="isOpen">
      <NavigationItem v-for="(item, index) in items" :item="item" :key="index" />
    </div>
  </div>
</template>

<script>
import useMenuItems from "@use/useMenuItems";
import menuType from "@data/menuTypes";
import NavigationItem from "./NavigationItem";
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";

export default {
  components: { NavigationItem },

  props: {
    identifier: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    menuName: {
      type: String,
      required: true,
    },
    menuTypes: {
      type: Array,
      required: false,
      default: () => [menuType.get().common],
    },
  },

  setup(props) {
    const store = useStore();

    const expandedNavigation = computed(() => {
      return store.getters["hac/expandableNavigation/expandedNavigation"];
    });

    const isOpen = ref(expandedNavigation.value.includes(props.identifier));

    const { items } = useMenuItems({
      menuName: props.menuName,
      menuTypes: props.menuTypes,
    });

    const icon = computed(() => {
      return isOpen.value ? "pi pi-arrow-down" : "pi pi-arrow-right";
    });

    const toggle = () => {
      store.dispatch("hac/expandableNavigation/toggle", props.identifier);
    };

    const reload = () => {
      isOpen.value = expandedNavigation.value.includes(props.identifier);
    };

    watch(expandedNavigation, reload);

    return {
      items,
      isOpen,
      toggle,
      icon,
    };
  },
};
</script>
