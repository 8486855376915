<template>
  <template v-if="item.separator">
    <BaseDivider />
    <div v-if="item.label" class="mb-3 pt-1 px-3 text-400">
      {{ item.label }}
    </div>
  </template>

  <template v-else>
    <NavigationLink
      :label="item.label"
      :icon="item.icon"
      :isActive="item.isActive"
      @click="goToPage(item.to, item.isActive)"
    />
  </template>
</template>

<script>
import NavigationLink from "./NavigationLink";

export default {
  components: {
    NavigationLink,
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  methods: {
    async goToPage(path, isActive) {
      if (isActive) return;

      try {
        await this.$router.push({ path });
        scrollTo(0, 0);
      } catch {
        return;
      }
    },
  },
};
</script>
