<template>
  <div class="flex-none flex w-17rem text-white sidebar--left">
    <div class="block w-full">
      <div class="mt-4 mb-4">
        <slot name="header" />
      </div>

      <div class="mt-4 mb-4">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SidebarLeft",
};
</script>

<style lang="scss" scoped>
.sidebar--left {
  box-shadow: 0 16px 24px rgba(0, 0, 0, 0.14);
}
</style>
