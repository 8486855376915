<template>
  <ExpandableNavigationMenu
    identifier="user"
    label="hac.navigation.menu.user"
    :menu-name="name"
    :menu-types="types"
  />
</template>

<script>
import ExpandableNavigationMenu from "../ExpandableNavigationMenu";
import menuName from "@data/menuNames";
import menuType from "@data/menuTypes";
import { ref } from "vue";

export default {
  components: { ExpandableNavigationMenu },

  setup() {
    const name = ref(menuName.get().user);
    const types = [menuType.get().common];

    return {
      name,
      types,
    };
  },
};
</script>
