<template>
  <BaseHover v-model="hover" class="cursor-pointer select-none">
    <div class="menuItem__wrapper">
      <div class="flex justify-content-end">
        <div class="menuItemDeco" :style="menuItemDecoStyle">
          <div class="menuItemDeco menuItemDeco--top" :style="menuItemDecoBackground"></div>
        </div>
      </div>

      <div class="pl-4 py-3 flex align-items-center flex-wrap menuItem" :style="menuItemStyle">
        <BaseIcon :icon="icon" :style="colorIcon" class="mr-4" size="1.3" />
        <div :style="[colorItem, { paddingTop: '2px' }]">
          {{ label }}
        </div>
      </div>

      <div class="flex justify-content-end">
        <div class="menuItemDeco" :style="menuItemDecoStyle">
          <div class="menuItemDeco menuItemDeco--bottom" :style="menuItemDecoBackground"></div>
        </div>
      </div>
    </div>
  </BaseHover>
</template>

<script>
export default {
  name: "NavigationItem",

  data() {
    return {
      hover: false,
    };
  },

  props: {
    label: {
      type: String,
      required: true,
    },

    isActive: {
      type: Boolean,
      required: false,
      default: false,
    },

    icon: {
      type: String,
      required: false,
      default: "pi pi-th-large",
    },
  },

  computed: {
    colorItem() {
      return {
        color: this.isActive
          ? "var(--gc-color-navigation-active-text)"
          : "var(--gc-color-navigation-inactive-text)",
      };
    },

    colorIcon() {
      return {
        color: this.isActive
          ? "var(--gc-color-navigation-active-icon)"
          : "var(--gc-color-navigation-inactive-icon)",
      };
    },

    menuItemStyle() {
      const style = {
        backgroundColor: "var(--gc-color-navigation-inactive)",
      };

      if (this.isActive) {
        style.backgroundColor = "var(--gc-color-navigation-active)";
      }

      if (this.hover && !this.isActive) {
        style.backgroundColor = "var(--gc-color-navigation-hover)";
      }

      return style;
    },

    menuItemDecoStyle() {
      return {
        backgroundColor: this.isActive
          ? "var(--gc-color-navigation-active)"
          : "var(--gc-color-navigation-inactive)",
      };
    },

    menuItemDecoBackground() {
      return {
        backgroundColor: this.isActive
          ? "var(--gc-color-background)"
          : "var(--gc-color-navigation-inactive)",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
$decosize: 0;

.menuItem__wrapper {
  margin-top: -#{$decosize};
  margin-bottom: -#{$decosize};
}

.menuItemDeco {
  height: $decosize;
  width: $decosize;

  &--top {
    border-bottom-right-radius: $decosize;
  }
  &--bottom {
    border-top-right-radius: $decosize;
  }
}
</style>
