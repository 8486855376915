<template>
  <div class="appLogo overflow-hidden">
    <img :src="logo" class="w-full h-auto" alt="Logo" />
  </div>
</template>

<script>
import logo from "@/assets/img/logo.svg";

export default {
  name: "AppLogo",

  computed: {
    logo() {
      return logo;
    },
  },
};
</script>

<style lang="scss" scoped>
.appLogo {
  position: relative;
  width: 145px;
  margin: auto;
}
</style>
